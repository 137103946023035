<template>
  <div class="app-container app-theme-white">
    <Navbar />
    <div class="app-main">
      <template v-if="user">
        <Sidebar v-if="user.tipo == 'dev' || user.tipo == 'admin'" />
      </template>
      <div class="app-main__outer">
        <div class="app-main__inner">
          <TituloComponente :titleCard="titleCard" :iconoTitulo="iconoTitulo" :toCreateAction="toCreateAction" :toCreateText="toCreateText" />
          <div class="tab-content">
            <div
              class="tab-pane tabs-animation fade show active"
              id="tab-content-0"
              role="tabpanel"
            >
              <div class="row">
                <div class="col-md-12">
                  <router-view ref="router"></router-view>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/layouts/Navbar.vue";
import Sidebar from "@/components/layouts/Sidebar.vue";
import TituloComponente from "@/components/layouts/TituloComponente.vue";
import Vuex from "vuex";

export default {
  name: "Main",
  components: {
    Navbar,
    Sidebar,
    TituloComponente,
  },
  computed: {
    ...Vuex.mapState(["user"]),
  },
  data() {
    return {
      iconoTitulo: "",
      titleCard: "",
      toCreateAction: "",
      toCreateText: "",
    };
  },
  mounted() {
      this.actualizarProps();
  },
  updated() {
      this.actualizarProps();
  },
  methods:{
      actualizarProps(){
          this.iconoTitulo = this.$refs.router._props.iconoTitulo;
          this.titleCard = this.$refs.router._props.titleCard;
          this.toCreateAction = this.$refs.router._props.toCreateAction;
          this.toCreateText = this.$refs.router._props.toCreateText;
    }
  }
};
</script>