<template>
  <div class="app-sidebar">
    <div class="app-header__logo">
      <div class="logo-src" :class="logoClass"></div>
      <div class="header__pane ml-auto">
        <div>
          <button
            type="button"
            class="hamburger close-sidebar-btn hamburger--elastic"
            data-class="closed-sidebar"
          >
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="app-header__mobile-menu">
      <div>
        <button
          type="button"
          class="hamburger hamburger--elastic mobile-toggle-nav"
        >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
    <div class="app-header__menu">
      <span>
        <button
          type="button"
          class="
            btn-icon btn-icon-only btn btn-primary btn-sm
            mobile-toggle-header-nav
          "
        >
          <span class="btn-icon-wrapper">
            <i class="fa fa-ellipsis-v fa-w-6"></i>
          </span>
        </button>
      </span>
    </div>
    <div class="scrollbar-sidebar">
      <div class="app-sidebar__inner">
        <ul class="vertical-nav-menu">
          <li class="app-sidebar__heading">Menu</li>
          <li>
            <router-link :to="{ name: 'Inicio' }">
              <i class="metismenu-icon fas fa-home"></i>
              Inicio
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'ListarInstituciones' }"
              v-if="user.tipo == 'dev'"
            >
              <i class="metismenu-icon fas fa-building"></i>
              Instituciones
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'ListarAdministradores' }"
              v-if="user.tipo == 'dev'"
            >
              <i class="metismenu-icon fas fa-users"></i>
              Administradores
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'ListarUsuarios' }"
              v-if="user.tipo == 'dev'"
            >
              <i class="metismenu-icon fas fa-users"></i>
              Usuarios
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ListarLiquidaciones' }">
              <i class="metismenu-icon fas fa-file-alt"></i>
              Listar liquidaciones
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'CargaMasiva' }">
              <i class="metismenu-icon fas fa-file-upload"></i>
              Cargar liquidacion
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'MisRecibos' }">
              <i class="metismenu-icon fas fa-file-invoice-dollar"></i>
              Mis Recibos
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/js/demo";
import "metismenu";
import $ from "jquery";
import Vuex from "vuex";
import PerfectScrollbar from "perfect-scrollbar";
export default {
  name: "Sidebar",
  data() {
    return {};
  },
  computed: {
    logoClass: () => {
      if (window.location.hostname == "recibos.pampadev.com.ar") {
        return " desarrollo ";
      } else if (window.location.hostname == "hma.misrecibosdigitales.com.ar") {
        return " ima ";
      } else if (
        window.location.hostname == "nsl.misrecibosdigitales.com.ar"
      ) {
        return " isnsl ";
      } else if (
        window.location.hostname == "isnsl.misrecibosdigitales.com.ar"
      ) {
        return " isnsl ";
      } else if (
        window.location.hostname == "diocesis.misrecibosdigitales.com.ar"
      ) {
        return " diocesis ";
      } else if (
        window.location.hostname == "isjb.misrecibosdigitales.com.ar"
      ) {
        return " isjb ";
      } else {
        return " desarrollo ";
      }
    },
    ...Vuex.mapState(["user"]),
  },
  mounted() {
    require("@/assets/js/eventosdemenu");
    $(".vertical-nav-menu").metisMenu();
    if ($(".scrollbar-container")[0]) {
      new PerfectScrollbar(".scrollbar-sidebar", {
        wheelSpeed: 2,
        wheelPropagation: true,
        minScrollbarLength: 20,
      });
    }
  },
};
</script>
